import { CalendarEventRepoImpl } from "domain/repository/CalendarEvent/CalendarEventRepoImpl"; 
import { useCalendarEventMaintenanceTracked } from "presentation/store/CalendarEvent/CalendarEventMaintenanceProvider"; 
import { CalendarEventMaintenanceVM } from "presentation/viewModel/CalendarEvent/CalendarEventMaintenanceVM"; 
import { useMemo } from "react"; 
export const useCalendarEventMaintenanceVM = () => { 
    const [, setCalendarEventMaintenanceState] = useCalendarEventMaintenanceTracked(); 
    const CalendarEventMainVM = useMemo(() => 
        CalendarEventMaintenanceVM({ 
            dispatch: [setCalendarEventMaintenanceState], 
            repo: CalendarEventRepoImpl(), 
        }), [setCalendarEventMaintenanceState]) 
    return CalendarEventMainVM 
} 
