import { CalendarEventEntity, EMPTY_CALENDAR_EVENT_ENTITY } from "domain/entity/CalendarEvent/CalendarEventEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CalendarEventRepository } from "domain/repository/CalendarEvent/CalendarEventRepo";
import _ from "lodash";
import { createCalendarEventMaintenanceValidationSchema } from "presentation/constant/CalendarEvent/CalendarEventMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { CalendarEventMaintenanceModel } from "presentation/model/CalendarEvent/CalendarEventMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
interface CalendarEventMaintenanceVMProps extends BaseViewModel { 
    dispatch: [ 
        Dispatch<SetStateAction<CalendarEventMaintenanceModel>> | ((value: SetStateAction<CalendarEventMaintenanceModel>) => void), 
    ], 
    repo: CalendarEventRepository, 
} 
export const CalendarEventMaintenanceVM = ({dispatch,repo}:CalendarEventMaintenanceVMProps) => { 
    const [calendarEventMainDispatch] = dispatch; 

    const loadDropdownOption = async () => { 
    } 
    const onSearch = async () => { 
        const entities = await repo.getEntities(); 
        calendarEventMainDispatch(prevState =>({ 
            ...prevState, 
            tableData: entities, 
            selectedRows: [], 
            currentSelectedRow: EMPTY_CALENDAR_EVENT_ENTITY, 
            isBackMaster: false, 
        })) 
    } 
    const updateSelectedRows = async (allRows:CalendarEventEntity[], selecedRows:CalendarEventEntity[]) => { 
        calendarEventMainDispatch(prevState => { 

            return { 
                ...prevState, 
                tableData: [...allRows], 
                selectedRows: selecedRows, 
            } 
        }) 
    } 
    const onAdd = () => { 
        calendarEventMainDispatch(prevState => { 

            return { 
                ...prevState, 
                isShowEditPanel: true,
                masterState:{ 
                    ...prevState.masterState, 
                    isAdd: true, 
                    isEditable: false, 
                    isRead: false, 
                    editingEntity: EMPTY_CALENDAR_EVENT_ENTITY, 
                } 
            } 
        }) 
    } 
    const onEdit = (currentEntity: CalendarEventEntity)=>{ 
        calendarEventMainDispatch(prevState => { 
            return { 
                ...prevState, 
                isShowEditPanel: true,
                currentSelectedRow: currentEntity, 
                masterState:{ 
                    ...prevState.masterState, 
                    isAdd: false, 
                    isEditable: false, 
                    isRead: false, 
                    editingEntity: { 
                        ...currentEntity 
                    }, 
                } 
            } 
        }) 
    } 

    const onEditClick = (currentEntity: CalendarEventEntity)=>{ 
        calendarEventMainDispatch(prevState => { 
            return { 
                ...prevState, 
                isShowEditPanel: true,
                currentSelectedRow: currentEntity, 
                masterState:{ 
                    ...prevState.masterState, 
                    isAdd: false, 
                    isEditable: true, 
                    isRead: false, 
                    editingEntity: { 
                        ...currentEntity 
                    }, 
                } 
            } 
        }) 
    }

    const onReset = ()=>{ 
        calendarEventMainDispatch(prevState => { 
            const resetEntity = prevState.masterState.isAdd ? EMPTY_CALENDAR_EVENT_ENTITY : prevState.currentSelectedRow; 
            return { 
                ...prevState, 
                masterState:{ 
                    ...prevState.masterState, 
                    editingEntity: { 
                        ...resetEntity 
                    }, 
                } 
            } 
        }) 
    } 
    const onClose = () => { 
        calendarEventMainDispatch(prevState => { 
            return { 
                ...prevState, 
                currentSelectedRow: EMPTY_CALENDAR_EVENT_ENTITY, 
                selectedRows: [], 
                masterState: { 
                    ...prevState.masterState, 
                    isAdd: false, 
                    isEditable: false, 
                    isRead: true, 
                    editingEntity: EMPTY_CALENDAR_EVENT_ENTITY, 
                    allFormState:{} 
                }, 
                isBackMaster: true, 
                isShowEditPanel: false,
            } 
        }) 
    } 
    const onSaveClicked = () => { 
        calendarEventMainDispatch(prevState => { 
            return { 
                ...prevState, 
                masterState: { 
                    ...prevState.masterState, 
                    isSaveClicked: true, 
                    allFormState: {}, 
                } 
            } 
        }) 
    } 
    const onSave = async (currentEntity: CalendarEventEntity,isAdd: boolean) => { 
        const valResult = isAdd? await Validation(createCalendarEventMaintenanceValidationSchema).ValidateFormOnly(currentEntity) : null; 
        let validatedResult: {[x: string]: string} = {};   
        if (valResult) { 
            validatedResult = {...valResult, warningMessage: 'Please input the missing value.' };  

            calendarEventMainDispatch(prevState => { 
                return { 
                    ...prevState, 
                    masterState: { 
                        ...prevState.masterState, 
                        allFormState: { 
                            ...validatedResult 
                        }, 
                    }                         
                } 
            }); 
            const res: ResponseEntity={
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        } 
        if(isAdd){ 
            return await repo.createEntity(currentEntity); 
        }else{ 
            return await repo.updateEntity(currentEntity); 
        } 
    } 
    const onCheckboxChange = (checked: boolean, fieldName: string) => { 
        calendarEventMainDispatch(prevState => ({ 
            ...prevState, 
            masterState:{ 
                ...prevState.masterState, 
                editingEntity : { 
                    ...prevState.masterState.editingEntity, 
                    [fieldName]: checked?"Y":"N", 
                } 
            } 
        })) 
    } 
    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => { 
        let val: any = fieldValue; 
        if (_.isArray(val)) { 
            val = _.uniq(val?.map((item: any) => item.value || item.key)); 
        } 
        if(fieldKey === 'CalendarEvent'){ 
            val = val.toUpperCase().replace(/\s+/g, ''); 
        } 
        calendarEventMainDispatch(prevState => { 
            return { 
                ...prevState, 
                masterState:{ 
                    ...prevState.masterState, 
                    editingEntity: { 
                        ...prevState.masterState.editingEntity, 
                        [fieldKey]: val, 
                    }, 
                    allFormState:{ 
                        ...prevState.masterState.allFormState, 
                        [fieldKey]: '', 
                    } 
                } 
            } 
        }) 
    } 
    return { 
        loadDropdownOption: loadDropdownOption, 
        updateSelectedRows: updateSelectedRows, 
        onAdd: onAdd, 
        onEdit: onEdit, 
        onReset: onReset, 
        onClose: onClose, 
        onSearch: onSearch, 
        onSaveClicked: onSaveClicked, 
        onSave: onSave, 
        onCheckboxChange: onCheckboxChange, 
        onFieldChange: onFieldChange, 
        onEditClick: onEditClick,
    } 
} 
