import { useCalendarEventMaintenanceTracked } from "presentation/store/CalendarEvent/CalendarEventMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import CalendarEventMaintenance from "./CalendarEventMaintenance";
import { CalendarEventMaintenanceFormPanel } from "./CalendarEventMaintenanceFormPanel";
export const CalendarEventMasterView: React.FC = () => { 
    const [calendarEventState] = useCalendarEventMaintenanceTracked(); 
    const { isShowEditPanel } = calendarEventState;
    return <> 
        <div className="main-comp-wrapper im-hide-side-form-draggable"> 
            <CalendarEventMaintenance/> 
            { 
                (isShowEditPanel &&  
                <SliderPanel 
                    isOpen={false} 
                    leftSectionWidth={"100%"} 
                    rightSectionWidth={"0%"} 
                    leftChildren={<CalendarEventMaintenanceFormPanel/>} 
                /> 
                ) 
            } 
        </div> 
    </> 
} 
