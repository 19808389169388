import { CalendarEventMaintenanceConstant } from "presentation/constant/CalendarEvent/CalendarEventMaintenanceConstant";
import { useCalendarEventMaintenanceVM } from "presentation/hook/CalendarEvent/useCalendarEventMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCalendarEventMaintenanceTracked } from "presentation/store/CalendarEvent/CalendarEventMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
const CalendarEventEditPanelTitleBar: React.FC = () => { 
    const [calendarEventState] = useCalendarEventMaintenanceTracked(); 
    const calendarEventVM = useCalendarEventMaintenanceVM(); 
    const messageBarVM = useMessageBarVM(); 
    const {currentSelectedRow, masterState} = calendarEventState; 
    const {editingEntity, isAdd, isEditable, isRead} = masterState; 

    const [isLoading, setIsLoading] = useState(false); 
    const currentEntity = isRead? currentSelectedRow : editingEntity; 
    const handleReset = useCallback(async () => { 
        calendarEventVM.onReset(); 
    }, [calendarEventVM]); 
    const handleEdit = useCallback(()=>{ 
        calendarEventVM.onEditClick(currentEntity); 
    },[currentEntity, calendarEventVM]) 
    const handleSave = useCallback(async () => { 
        setIsLoading(true); 
        try { 
            calendarEventVM.onSaveClicked(); 
            const res = await calendarEventVM.onSave(currentEntity,isAdd); 
            if(!!!res || !res.success){ 
                messageBarVM.showWarining(res?.data??'Save data failed.'); 
            }else { 
                calendarEventVM.onClose(); 
                setIsLoading(true); 
            } 
        } catch (error) { 

            messageBarVM.showError('Save data failed.');
        }finally{ 
            setIsLoading(false) 
        } 

    },[currentEntity, isAdd, messageBarVM, calendarEventVM]); 
    return <Sidebarheader style={{width: '100%'}}> 
        {isLoading && <Loader Indicator="Spinner" size="Medium" />} 
        <Breadcrumb> 
            <HPHBreadcrumb breadcrumbData={[{title: CalendarEventMaintenanceConstant.CATEGORY}, 
                { title:  CalendarEventMaintenanceConstant.TITLE }]}onCurrentClick={()=>{}}> 
            </HPHBreadcrumb> 
        </Breadcrumb> 
        <StyledAction> 
            {!(isAdd || isEditable) && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />}
            {<IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={calendarEventVM.onClose} /> }
            {(isAdd || isEditable) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} /> }
        </StyledAction> 
    </Sidebarheader> 
} 
export default memo(CalendarEventEditPanelTitleBar); 
