import { CalendarEventEntity } from "domain/entity/CalendarEvent/CalendarEventEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { CalendarEventRepository } from "./CalendarEventRepo";
/** 
 * For template 
 * @returns  
 */ 
export const CalendarEventRepoImpl = (): CalendarEventRepository => { 
    const url = '/v1/calendarEvent'; 
    const getEntities = async() : Promise<CalendarEventEntity[]> => { 
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => { 
            return res.data 
        }).catch(err => { 
            return []; 
        }); 

    } 
    const getEntityByKey = async(key: string): Promise<CalendarEventEntity> => { 
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => { 
            return res.data; 
        }).catch(err => { 
            return null; 
        }); 
    } 
    const createEntity = async(newData: CalendarEventEntity) : Promise<ResponseEntity> => { 
        return await axiosPostData(referenceAxiosInstance, `${url}`, newData); 
    } 
    const updateEntity = async(updatedData: CalendarEventEntity): Promise<ResponseEntity> => { 
        return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData); 
    } 
    const deleteEntity= async(key: string): Promise<ResponseEntity> => { 
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key); 
    } 
    return { 
        getEntities: getEntities, 
        getEntityByKey: getEntityByKey, 
        createEntity: createEntity, 
        updateEntity: updateEntity, 
        deleteEntity: deleteEntity, 
    } 
} 
