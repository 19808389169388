import { CalendarEventMaintenanceConstant } from "presentation/constant/CalendarEvent/CalendarEventMaintenanceConstant";
import { eventTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useCalendarEventMaintenanceVM } from "presentation/hook/CalendarEvent/useCalendarEventMaintenanceVM";
import { useCalendarEventMaintenanceTracked } from "presentation/store/CalendarEvent/CalendarEventMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import CalendarEventEditPanelTitleBar from "./CalendarEventEditPanelTitleBar";
export const CalendarEventMaintenanceFormPanel = () => { 
    const [calendarEventState] = useCalendarEventMaintenanceTracked(); 
    const calendarEventVM = useCalendarEventMaintenanceVM(); 
    const {currentSelectedRow, masterState} = calendarEventState; 
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState; 
    const SCREEN_CONSTANT = CalendarEventMaintenanceConstant; 

    const currentEntity = isRead? currentSelectedRow : editingEntity; 
    const memoEventType = useMemo(() => 
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}> 
            <NbisCommonField 
                errorMessages={allFormState} 
                isReadOnly={!isAdd} 
                isShowOptional={false} 
                readOnlyValue={currentEntity?.eventType || ''} 
                fieldValue={currentEntity?.eventType} 
                fieldLabel={SCREEN_CONSTANT.EVENT_TYPE} 
                isSaveClicked={isSaveClicked} 
                fieldType={FieldType.DROPDOWN} 
                fieldKey={'eventType'} 
                isShowMissingError={true} 
                maxLength={60} 
                options={eventTypeDropdownOption}
                requiredFieldList={[]} 
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                    calendarEventVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
        </div> 
        , [allFormState, isAdd, currentEntity?.eventType, SCREEN_CONSTANT.EVENT_TYPE, isSaveClicked, calendarEventVM]) 
        const memoDesc = useMemo(() => 
            <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    errorMessages={allFormState} 
                    isReadOnly={!(isEditable || isAdd)} 
                    isShowOptional={true} 
                    readOnlyValue={currentEntity?.eventDesc || ''} 
                    fieldValue={currentEntity?.eventDesc} 
                    fieldLabel={SCREEN_CONSTANT.DESC} 
                    isSaveClicked={isSaveClicked} 
                    fieldType={FieldType.TEXT} 
                    fieldKey={'eventDesc'} 
                    isShowMissingError={true} 
                    maxLength={60} 
                    requiredFieldList={[]} 
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                        calendarEventVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
            </div> 
            , [allFormState, isEditable, isAdd, currentEntity?.eventDesc, SCREEN_CONSTANT.DESC, isSaveClicked, calendarEventVM]) 
        const memoEventDate = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"330px", marginBottom:"24px"}}> 
                <DatePickerComponent 
                        disabled={!isAdd} 
                        label={SCREEN_CONSTANT.EVENT_DATE} 
                        width="330px" 
                        date={currentEntity?.eventDate} 
                        fieldName="eventDate" 
                        errorMessage={allFormState?allFormState["eventDate"]:""} 
                        onDateChange={(fieldKey: string, fieldValue: IFieldValue) =>  
                            calendarEventVM.onFieldChange(fieldKey, fieldValue)}/> 
            </div> 
        , [SCREEN_CONSTANT.EVENT_DATE, allFormState, calendarEventVM, currentEntity?.eventDate, isAdd]) 
        
        const memoApplyFspAdj = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}> 
                <NbisCommonField 
                    fieldKey={"fspAdjustmentInd"} 
                    requiredFieldList={[]} 
                    fieldLabel={SCREEN_CONSTANT.FSP_ADJ}  
                    fieldType={FieldType.TEXT}  
                    isReadOnly={true} 
                    onFieldChange={()=>{}}/> 
                <HPHCheckbox 
                    label={""} 
                    checked={currentEntity?.fspAdjustmentInd==='Y'} 
                    disabled={!(isEditable || isAdd)} 
                    onChange={(e) => calendarEventVM.onCheckboxChange(e.checked, "fspAdjustmentInd")} 
                /> 
            </div> 
        , [SCREEN_CONSTANT.FSP_ADJ, calendarEventVM, currentEntity?.fspAdjustmentInd, isAdd, isEditable]) 
        const memoActiveInd = useMemo(() => 
            <> 
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    fieldKey={"activeInd"} 
                    requiredFieldList={[]} 
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}  
                    fieldType={FieldType.TEXT}  
                    isReadOnly={true} 
                    onFieldChange={()=>{}}/> 
                <HPHCheckbox 
                    label={""} 
                    checked={currentEntity?.activeInd==='Y'} 
                    disabled={!(isEditable || isAdd)} 
                    onChange={(e) => calendarEventVM.onCheckboxChange(e.checked, "activeInd")} 
                /> 
            </div> 
            </> 
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isEditable, isAdd, calendarEventVM]) 

    const memoEditPanelTitleBar = useMemo(() => { 
        return <CalendarEventEditPanelTitleBar/> 
    }, []) 
    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'> 
        {/* Header part of add or edit record */} 
        {memoEditPanelTitleBar} 
        {/* Readable and Editable Form */} 
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}> 
                <div className="flex-row-auto"> 
                    <div className={'flex-row-item'}> 
                        <div className={'im-flex-row'}> 
                            <div className={'flex-row-item-stretch'}> 
                                <SidebarTitle>{!isAdd ? currentEntity.eventType : "NEW EVENT"}</SidebarTitle> 
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>} 
                            </div> 
                        </div> 

                        <CriteriaItemContainer> 
                            {memoEventType} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoDesc} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoEventDate} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoApplyFspAdj} {memoActiveInd} 
                        </CriteriaItemContainer> 
                    </div> 
                </div> 
            </div> 
        {/* Readable and Editable Form */} 
    </StyledSidebar> 
} 
