export const CalendarEventMaintenanceRequiredFieldList: string[] = [ 
    'eventType', 
    'eventDate',  
]; 
export const CalendarEventMaintenanceConstant = {  
    CATEGORY: "CODE",   
    TITLE: "Calendar Event", 
    EVENT_TYPE: "Event Type", 
    EVENT_DATE: 'Event Date', 
    DESC: 'Event Desc.', 
    FSP_ADJ: 'Apply FSP Adjust', 
    ACTIVE_IND: 'Active Ind.', 
} 
