import { Permission } from "presentation/constant/ANAInfo/PermissionName"; 
import { CalendarEventMaintenanceProvider } from "presentation/store/CalendarEvent/CalendarEventMaintenanceProvider"; 
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper"; 
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper"; 
import { CalendarEventMasterView } from "presentation/view/section/CalendarEvent/CalendarEventMasterView"; 
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled"; 
const CalendarEventMaintenanceCont = () => { 
    return <ANAInfoWrapper permission={Permission.CALENDAR_EVENT_MAINTENANCE}> 
        <MessageBarWrapper> 
            <CalendarEventMaintenanceProvider> 
                <GridStyles/> 
                <CalendarEventMasterView/> 
            </CalendarEventMaintenanceProvider> 
        </MessageBarWrapper> 
    </ANAInfoWrapper> 
} 
export default CalendarEventMaintenanceCont; 
