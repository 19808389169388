export interface CalendarEventEntity { 
    id:number, 
    eventType: string, 
    eventDesc: string|null, 
    fspAdjustmentInd: string, 
    eventDate: Date | null, 
    activeInd: string|null, 
    [key:string]: string | number | Date| null | undefined 
} 
export const EMPTY_CALENDAR_EVENT_ENTITY:CalendarEventEntity = { 
    id: 0, 
    eventType: "", 
    eventDesc: null, 
    fspAdjustmentInd: "Y", 
    eventDate: null, 
    activeInd: "Y" 
} 
