import { CalendarEventEntity, EMPTY_CALENDAR_EVENT_ENTITY } from "domain/entity/CalendarEvent/CalendarEventEntity"; 
import { BaseViewChangeSate } from "../BaseViewChangeState"; 
import { DropdownProps } from "../DropdownProps"; 
export interface TariffCodeDropdownOptions { 
    CalendarEventTypeDropdownOptions: DropdownProps[], 
} 
export interface MasterChangeState extends BaseViewChangeSate { 
    editingEntity: CalendarEventEntity, 
} 
export interface CalendarEventMaintenanceModel { 
    isLoading: boolean, 
    tableData: CalendarEventEntity[], 
    currentSelectedRow: CalendarEventEntity, 
    selectedRows: CalendarEventEntity[], 
    dynamicOptions: TariffCodeDropdownOptions, 
    masterState: MasterChangeState, 
    isBackMaster: boolean, 
    isShowEditPanel: boolean,
} 
export const EMPTY_MASTER_CALENDAR_EVENTANY_MODEL: CalendarEventMaintenanceModel = { 
    isLoading: false, 
    tableData: [], 
    currentSelectedRow: { ...EMPTY_CALENDAR_EVENT_ENTITY }, 
    selectedRows: [], 
    dynamicOptions: { 
        CalendarEventTypeDropdownOptions: [] 
    }, 
    masterState: { 
        isSliderOpen: false, 
        isTabularDataActive: false, 
        isAdd: false, 
        isRead: false, 
        isEditable: false, 
        isSaveClicked: false, 
        lastEditRowId: "", 
        isSelected: false, 
        slider: { 
            isAdd: false, 
            isRead: false, 
            isEditable: false 
        }, 
        editingEntity: EMPTY_CALENDAR_EVENT_ENTITY, 
    }, 
    isBackMaster: false, 
    isShowEditPanel: false,
} 
